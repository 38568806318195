
import { Component, Vue } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { client } from '@/store/modules/client';
import { applyMask, mobilePhoneMask } from '@/components/registration/settings/mask';
import i18n from '@/core/plugins/vue-i18n';
import { content } from '@/store/modules/content';
import { SECTIONS } from '@/store/modules/models/content';
import { Address } from '@/api/models/address';
import { CARD_PLACEHOLDER } from '@/views/profile/Edit/values';

function getFullAddress(address: Address) {
  const addressParts: string[] = [];
  if (address.city) {
    addressParts.push(address.city);
  }

  if (address.street) {
    addressParts.push(address.street);
  }

  if (address.fields.house) {
    addressParts.push(address.fields.house);
  }

  let fullAddress = addressParts.join(', ');

  if (address.fields.apartment) {
    fullAddress += ' - ' + address.fields.apartment;
  }

  return fullAddress;
}

interface SectionItem {
  name: string;
  title: string;
  value: string;
}

@Component({
  name: 'ProfileEditPage',
  components: { LmsInput, Field }
})
export default class extends Vue {
  client = client;

  get sections(): SectionItem[] {
    return [
      {
        name: 'firstName',
        title: this.$t('PROFILE_INFO.FIRST_NAME').toString(),
        value: client.firstName || '-'
      },
      {
        name: 'lastName',
        title: this.$t('PROFILE_INFO.LAST_NAME').toString(),
        value: client.lastName || '-'
      },
      {
        name: 'phone',
        title: this.$t('PROFILE_INFO.PHONE').toString(),
        value: applyMask(client.profile.mobilePhone, mobilePhoneMask)
      },
      {
        name: 'email',
        title: this.$t('PROFILE_INFO.EMAIL').toString(),
        value: client.profile.email
      },
      {
        name: 'declaredAddress',
        title: this.$t('PROFILE_INFO.DECLARED_ADDRESS').toString(),
        value: this.declaredAddress
      },
      {
        name: 'bankAccount',
        title: this.$t('PROFILE_INFO.BANK_ACCOUNT').toString(),
        value: this.bankAccountNumber
      },
      {
        name: 'workInfo',
        title: this.$t('PROFILE_INFO.WORK_INFO').toString(),
        value: this.workInfo
      },
      {
        name: 'bankCard',
        title: this.$t('PROFILE_INFO.CARD').toString(),
        value: this.bankCard
      }
    ];
  }

  loading = true;

  get declaredAddress() {
    return client.declaredAddress ? getFullAddress(client.declaredAddress) : CARD_PLACEHOLDER;
  }

  get bankAccountNumber() {
    return client.currentBankAccountNumber ? String(client.currentBankAccountNumber) : CARD_PLACEHOLDER;
  }

  get bankCard() {
    return client.bankCard ? String(client.bankCard) : CARD_PLACEHOLDER;
  }

  get workInfo() {
    if (!client.workInfo) {
      return '-';
    }
    const workParts: string[] = [];

    if (client.workInfo.employmentStatus && this.employmentStatus) {
      workParts.push(this.employmentStatus[client.workInfo.employmentStatus]);
    }
    if (client.workInfo.companyName) {
      workParts.push(client.workInfo.companyName);
    }

    return workParts.join(', ');
  }

  get employmentStatus() {
    return content.contentSection(SECTIONS.EMPLOYMENT_STATUS, i18n.locale);
  }

  mounted() {
    client.initProfile({ force: true });
    content.loadContentSection({
      section: SECTIONS.EMPLOYMENT_STATUS,

      locale: i18n.locale
    });
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;

    try {
      await client.initProfileDisbursements();
    } finally {
      this.loading = false;
    }
  }
}
