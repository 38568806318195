
import { Component, Mixins } from 'vue-property-decorator';
import { agreements } from '@/store/modules/agreements';
import { client } from '@/store/modules/client';
import ScheduleGraphic from '@/components/schedule/ScheduleGraphic.vue';
import MyLoanPayment from '@/views/profile/Payment/Payment.vue';
import { ScheduleGraphicPayment } from '@/components/schedule/ScheduleGraphic.types';
import { Agreement, AGREEMENT_CONTRACT_STATUS, AgreementSchedule } from '@/api/models/agreements';
import { getAgreementSchedule } from '@/api/agreements';
import PaymentCardAdd from '@/views/profile/Payment/PaymentCard/PaymentCardAdd.vue';
import BankCardVerificationHandler from '@/components/verification/BankCardVerificationHandler.vue';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { MULTI_LOAN_ROUTES, PROFILE_ROUTES } from '@/router/routes_type';
import PaymentCardApprove from '@/views/profile/Payment/PaymentCard/PaymentCardApprove.vue';
import PaymentCardAddedSuccess from '@/views/profile/Payment/PaymentCard/PaymentCardAddedSuccess.vue';
import { DISBURSEMENT_TYPE, DisbursementCard } from '@/api/models/disbursement';
import { saveDisbursementType } from '@/api/client';
import { SKIP_ROUTE_CHECK } from '@/router/routes';
import { forEach } from 'lodash-es';

@Component({
  components: {
    PaymentCardAddedSuccess,
    PaymentCardApprove,
    PaymentCardAdd,
    MyLoanPayment,
    ScheduleGraphic
  }
})
export default class MyLoan extends Mixins(BankCardVerificationHandler, CheckRoutingMixin) {
  loading = true;
  agreements: Agreement[] = [];
  schedules: AgreementSchedule[] = [];
  client = client;
  graphicAgreementActive = '';
  agreementPaymentConfirationPopupActive = '';
  disableNewLoan = true;
  disbursement: DisbursementCard | null = client.bankCardSelected;
  selecting = false;

  async mounted() {
    await this.fetchAgreements();
    this.loading = false;
  }

  async fetchAgreements() {
    await agreements.initGetAgreementList({ force: true });
    this.agreements = agreements.getActiveAgreements;

    for (const agreement of this.agreements) {
      const { data: schedule } = await getAgreementSchedule(agreement.scheduleId);
      this.schedules.push(schedule);
    }

    this.disableNewLoan = this.agreements.some(
      agreement => agreement.contractStatus !== AGREEMENT_CONTRACT_STATUS.ISSUED
    );
  }

  get cards() {
    return client.disbursements[DISBURSEMENT_TYPE.BANK_CARD];
  }

  get isAdded() {
    return !!this.findDisbursement(this.getDisbursement(), this.cards);
  }

  async handleAccept() {
    const disbursement = this.getDisbursement();
    if (disbursement) {
      await saveDisbursementType(disbursement);
      client.storeCurrentCardDisbursement(disbursement);
      await client.loadProfileDisbursements();

      this.disbursement = disbursement;
      this.toggleSelect(false);
    }
  }

  private toggleSelect(force: boolean | null = null) {
    this.selecting = force === null ? !this.selecting : force;
  }

  agreementSchedule(agreementId: string): AgreementSchedule | null {
    return this.schedules.find(schedule => schedule.agreementId === agreementId) ?? null;
  }

  paymentsGraphic(agreementId: string): ScheduleGraphicPayment[] {
    return (this.agreementSchedule(agreementId)?.payments ?? []).map(payment => {
      return {
        number: payment.number,
        principal: payment.totalPrincipalAmount,
        paymentDate: payment.endDate,
        fees: payment.totalRegularAmount,
        totalToPay: payment.paymentAmount
      };
    });
  }

  showGraphic(agreementId: string): void {
    this.graphicAgreementActive = agreementId;
  }

  async navigate() {
    if (this.disableNewLoan) {
      return;
    }

    await this.fetchAgreements();
    if (this.disableNewLoan) {
      return;
    }

    const route = await this.checkRouteBeforeProgress(PROFILE_ROUTES.MY_LOAN_PAGE);
    if (route) return this.redirect(route);

    await this.redirect(MULTI_LOAN_ROUTES.CALCULATOR, { SKIP_ROUTE_CHECK: SKIP_ROUTE_CHECK }, true);
  }

  handleAdd(): void {
    this.startVerification();
  }

  handlePaymentConfirmationPopup(agreementId: string) {
    this.agreementPaymentConfirationPopupActive = agreementId;
  }
}
